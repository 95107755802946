import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';

import { LinearEncoding, sRGBEncoding } from 'three/src/constants';
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2 } from 'three';
import ReactAudioPlayer from 'react-audio-player';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import createAnimation from './converter';
import blinkData from './blendDataBlink.json';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import './App.css'

import * as THREE from 'three';
import axios from 'axios';
import moment from 'moment';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
const _ = require('lodash');
const host = 'https://api-ai-alpdoganco.azurewebsites.net'
const conversationId = moment().unix();

function Avatar({ avatar_url, speak, setSpeak, text, setAudioSource, playing, token }) {
  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;

  const [
    bodyTexture,
    eyesTexture,
    teethTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    teethNormalTexture,
    // teethSpecularTexture,
    hairTexture,
    tshirtDiffuseTexture,
    tshirtNormalTexture,
    tshirtRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,
  ] = useTexture([
    "/images/body.webp",
    "/images/eyes.webp",
    "/images/teeth_diffuse.webp",
    "/images/body_specular.webp",
    "/images/body_roughness.webp",
    "/images/body_normal.webp",
    "/images/teeth_normal.webp",
    // "/images/teeth_specular.webp",
    "/images/h_color.webp",
    "/images/tshirt_diffuse.webp",
    "/images/tshirt_normal.webp",
    "/images/tshirt_roughness.webp",
    "/images/h_alpha.webp",
    "/images/h_normal.webp",
    "/images/h_roughness.webp",
  ]);

  _.each([
    bodyTexture,
    eyesTexture,
    teethTexture,
    teethNormalTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    tshirtDiffuseTexture,
    tshirtNormalTexture,
    tshirtRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture
  ], t => {
    t.encoding = sRGBEncoding;
    t.flipY = false;
  });

  bodyNormalTexture.encoding = LinearEncoding;
  tshirtNormalTexture.encoding = LinearEncoding;
  teethNormalTexture.encoding = LinearEncoding;
  hairNormalTexture.encoding = LinearEncoding;


  gltf.scene.traverse(node => {


    if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {

      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;


      if (node.name.includes("Body")) {

        node.castShadow = true;
        node.receiveShadow = true;

        node.material = new MeshPhysicalMaterial();
        node.material.map = bodyTexture;
        // node.material.shininess = 60;
        node.material.roughness = 1.7;

        // node.material.specularMap = bodySpecularTexture;
        node.material.roughnessMap = bodyRoughnessTexture;
        node.material.normalMap = bodyNormalTexture;
        node.material.normalScale = new Vector2(0.6, 0.6);

        morphTargetDictionaryBody = node.morphTargetDictionary;

        node.material.envMapIntensity = 0.8;
        // node.material.visible = false;

      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;


      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {

        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.7;


      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        node.material.map = hairTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;

        node.material.transparent = true;
        node.material.depthWrite = false;
        node.material.side = 2;
        node.material.color.setHex(0x000000);

        node.material.envMapIntensity = 0.3;


      }

      if (node.name.includes("TSHIRT")) {
        node.material = new MeshStandardMaterial();

        node.material.map = tshirtDiffuseTexture;
        node.material.roughnessMap = tshirtRoughnessTexture;
        node.material.normalMap = tshirtNormalTexture;
        node.material.color.setHex(0xffffff);

        node.material.envMapIntensity = 0.5;


      }

      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }

    }

  });

  const [clips, setClips] = useState([]);

  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {

    if (speak === false)
      return;
    makeSpeech(text, token)
      .then(response => {

        let { blendData, filename } = response.data;
        console.log(filename);
        let newClips = [
          createAnimation(blendData, morphTargetDictionaryBody, 'HG_Body'),
          createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')];

        filename = host + filename;

        setClips(newClips);
        setAudioSource(filename);

      })
      .catch(err => {
        console.error(err);
        setSpeak(false);

      })

  }, [speak]);

  let idleFbx = useFBX('/idle.fbx');
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
    return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, track => {

    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }

    return track;

  });

  useEffect(() => {

    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'HG_Body');
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();


  }, []);

  // Play animation clips when available
  useEffect(() => {

    if (playing === false)
      return;

    _.each(clips, clip => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();

    });

  }, [playing]);


  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}


function makeSpeech(text, token) {
  return axios.post(host + '/talk', {
    "text": text,
    "g-recaptcha-response": token
    });
  }

const STYLES = {
  area: { position: 'absolute', bottom: '0', left: '0', zIndex: 500 },
  speak: { padding: '5px', display: 'block', color: '#FFFFFF', background: '#222222', border: 'None' },
  label: { color: '#777777', fontSize: '0.5em' },
}

function App() {

  const [chats, setChats] = useState([{ msg: 'Hi there! How can I assist you today?', who: 'bot', exct: '0' }])
  const [text, setText] = useState("Hello I am Kara, your 3D virtual assistant.");
  const [msg, setMsg] = useState("");
  const [exct, setexct] = useState("");
  const [load, setLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [visits, setVisits] = useState("--");
  const [token, setToken] = useState("");
  const getResponse = (msg, token) => {
    if (msg === '') {
      toast.error("Promt can't be empty.[In some browsers mic may not work]");
      return;
    }
    if (load === true || speak === true) {
      toast.error("Already generating response!");
      return;
    }
    setChats(chats => [...chats, { msg: msg, who: 'me' }])

    setMsg("");
    setLoad(true);

    const start = new Date();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    fetch(`${host}/ask`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        "text": msg,
        "conversationId": conversationId,
        "g-recaptcha-response": token
      })
    })
    .then(response => response.json())
    .then(result => {
      console.log(result)
      const timeTaken = (new Date()) - start;
      setSpeak(true);
      setText(result.text);
      setexct(timeTaken / 1000);
      setLoad(false)
    })
      .catch((error) => { alert('error: ', error.message); setLoad(false); setText("Sorry, API isn't working currently. try after some time.") });

  }

  useEffect(() => {
    if(document.querySelector('.chat-box'))
    document.querySelector('.chat-box').scrollTop = document.querySelector('.chat-box').scrollHeight;
  }, [chats, token])

  const audioPlayer = useRef();

  const [speak, setSpeak] = useState(false);
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  // End of play
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
  }

  // Player is read
  function playerReady(e) {
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
    setChats(chats => [...chats, { msg: text, who: 'bot', exct: exct }]);

  }
  const {
    transcript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const startListening = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening()
    }
    else {
      toast.error("Voice recognision not supported by browser.")
    }

  };
  const stopListening = () => {
    getResponse(msg, token);
    SpeechRecognition.stopListening();
  }

  useEffect(() => {
    setMsg(transcript);
  }, [transcript])

  if(!token){
    return (
      <GoogleReCaptcha
        onVerify={token => {
          console.log(token);
          setToken(token)
        }}
      />
    )
  }

  return (
    <React.Fragment>
     <React.Fragment>
        <div style={{
          display: "flex",
          height: "50px",
          background: "rgb(27, 103, 107)",
          alignItems: "center",
          fontWeight: "bold",
          paddingLeft: 10,
          paddingRight: 10,
          justifyContent: "space-between",
          color: "white",
        }}>
          <a style={{
            color: "white",
            textDecoration: "none"
          }} href="https://alpdogan.co">{"< "}Go Back</a>
          <span>ai.alpdogan.co</span>
        </div>

        <div className="full">
        <div style={STYLES.area}>
          <button style={STYLES.speak}>
            {speak || load ? 'Running...' : 'Type message.'}
          </button>
        </div>
        <div className='chat-div'>
          <div className='chat-box'>
            {chats.map((chat) => {
              if (chat.who === "me") {
                return <p className={chat.who}>
                  {chat.msg}
                </p>
              } else {
                return <p className={chat.who}>
                  {chat.msg}
                  <div className='time'>{"generated in " + chat.exct + "s"}</div>
                </p>
              }
            })}

            {(load == true || speak) && !playing ? <p style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
              <lottie-player src="https://lottie.host/8891318b-7fd9-471d-a9f4-e1358fd65cd6/EQt3MHyLWk.json" style={{ width: "50px", height: "50px" }} loop autoplay speed="1.4" direction="1" mode="normal"></lottie-player></p> : <></>}
          </div>
          <div className='msg-box'>
            <button className='msgbtn' id='mic' onTouchStart={startListening} onMouseDown={startListening} onTouchEnd={stopListening} onMouseUp={stopListening}>
              <img src='./images/icons/mic.png' alt='mic' unselectable='on'></img>
            </button>
            <input type='text' value={msg} onChange={e => setMsg(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') { getResponse(msg, token) } }} placeholder='Say hello or ask about me.'></input>
            <button className='msgbtn' id='send' onClick={() => { getResponse(msg, token) }}>
              <img src='./images/icons/send.png' alt='mic'></img>
            </button>
          </div>
        </div>
        <ReactAudioPlayer
          src={audioSource}
          ref={audioPlayer}
          onEnded={playerEnded}
          onCanPlayThrough={playerReady}

        />

        {/* <Stats /> */}
        <Canvas dpr={2} onCreated={(ctx) => {
          ctx.gl.physicallyCorrectLights = true;
        }}>

          <OrthographicCamera
            makeDefault
            zoom={1400}
            position={[0, 1.65, 1]}
          />

          {/* <OrbitControls
          target={[0, 1.65, 0]}
        /> */}

          <Suspense fallback={null}>
            <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
          </Suspense>

          <Suspense fallback={null}>
            <Bg />
          </Suspense>

          <Suspense fallback={null}>
            <Avatar
              avatar_url="/model-v3.glb"
              speak={speak}
              setSpeak={setSpeak}
              text={text}
              setAudioSource={setAudioSource}
              playing={playing}
              token={token}
            />
          </Suspense>
        </Canvas>
        <Loader dataInterpolation={(p) => `Loading... please wait`} />
        </div>
      </React.Fragment>
    </React.Fragment>
 
  )
}

function Bg() {

  const texture = useTexture('/images/bg5.jpg');

  return (
    <mesh position={[0, 1.5, -4]} scale={[1.2, 1.2, 1.2]}>
      <planeBufferGeometry />
      <meshBasicMaterial map={texture} />

    </mesh>
  )

}

export default App;
